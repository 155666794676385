<template>
  <DynamicAuctionItemListCarParameter
    v-if="mileage"
    v-bind="$attrs"
    :title="mileage.name"
    :value="formatDistance(mileage.value)"
    class="mileage lg:order-3"
  >
    <i class="ab-icon ab-icon-speedmeter h-8"></i>
  </DynamicAuctionItemListCarParameter>

  <DynamicAuctionItemListCarParameter
    v-else-if="totalMileage"
    v-bind="$attrs"
    :title="totalMileage.name"
    :value="formatDistance(totalMileage.value)"
    class="mileage lg:order-3"
  >
    <i class="ab-icon ab-icon-user-speedmeter h-8"></i>
  </DynamicAuctionItemListCarParameter>

  <DynamicAuctionItemListCarParameter
    v-else-if="unableToReadMileage"
    v-bind="$attrs"
    :value="unableToReadMileage.name"
    class="max-w-[120px] text-center sm:max-w-none sm:text-left lg:order-3"
  />
</template>

<script lang="ts" setup>
import type { AuctionCar } from '@autobid/ui/types/Car'
import { computed } from 'vue'

interface Props {
  car: AuctionCar
}

const props = defineProps<Props>()

const unableToReadMileage = computed(() => props.car.equipments.eq1143)
const mileage = computed(() => props.car.equipments.eq68)
const totalMileage = computed(() => props.car.equipments.eq32)
const unitFullForm = computed(() => props.car.equipments.eq139?.value)

function formatDistance(value: string) {
  return `${Number(value).toLocaleString('de-DE')} ${unitFullForm.value ?? ''}`
}
</script>
