import type { AuctionCar } from '@autobid/ui/types/Car'

export const useCarPower = () => {
  const { t } = useI18n()

  const powerTitle = t('shared-car.performance')

  const getPower = (eq: AuctionCar['equipments']): string | undefined => {
    const result = []

    if (eq.eq19) {
      result.push(`${eq.eq19.value} ${t('unit.kw')}`)
    }

    if (eq.eq226) {
      result.push(`${eq.eq226.value} ${t('unit.hp')}`)
    }

    if (!result.length) return

    return result.join(' / ')
  }

  return { powerTitle, getPower }
}
